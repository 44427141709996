import React, { useState } from 'react'
import SurveyBuilder from './components/SurveyBuilder'
import SurveyReport from './components/SurveyReport'
import { Route, Switch } from 'react-router-dom'

export default function SurveyCreator(props) {
  const locale = props.match.params.locale
  const pathname = props.location.pathname
  const course = props.course
  const [survey, setSurvey] = useState(props.survey)
  const [lection, setLection] = useState(props.lection)

  function updateLectionTitle(newTitle) {
    setLection({ ...lection, title: newTitle })
  }

  function updateLectionAssessment(newAssessment) {
    setLection({ ...lection, assessment: newAssessment })
  }

  function updateLectionAiRate(newAiRate) {
    setLection({ ...lection, ai_rate: newAiRate })
  }

  return (
    <>
      <Switch>
        <Route
          exact
          path="/:locale/tutor/courses/:courseId/surveys/:surveyId/edit"
          render={(props) => <SurveyBuilder {...props} survey={survey} course={course} lection={lection}
            updateLectionTitle={updateLectionTitle} updateLectionAssessment={updateLectionAssessment} updateLectionAiRate={updateLectionAiRate} pathname={pathname} />}
        />
        <Route
          exact
          path="/:locale/tutor/courses/:courseId/surveys/:surveyId/report"
          render={(props) => <SurveyReport {...props} survey={survey} course={course} />}
        />
      </Switch>
    </>
  )
}